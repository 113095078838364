"use client";

import { ParseProvider } from "./parseContext";
import { SurveyProvider } from "./surveyContext";
import { QuestionsProvider } from "./questionsContext";
import { SurveyDataProvider } from "./surveyDataContext";
import { UserProvider } from "./userContext";
import { ExperiencesProvider } from "./experienceContext";
import { QCategoriesProvider } from "./qcategoryContext";
import { SelectedExperProvider } from "./selectedExperContext";
import { CustomExperProvider } from "./customExperContext";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ParseProvider>
      <UserProvider>
        <SurveyProvider>
          <QuestionsProvider>
            <SurveyDataProvider>
              <ExperiencesProvider>
                <QCategoriesProvider>
                  <SelectedExperProvider>
                    <CustomExperProvider>{children}</CustomExperProvider>
                  </SelectedExperProvider>
                </QCategoriesProvider>
              </ExperiencesProvider>
            </SurveyDataProvider>
          </QuestionsProvider>
        </SurveyProvider>
      </UserProvider>
    </ParseProvider>
  );
}
